<template>
  <div>
    <div class="loading" v-if="loading">
      <van-loading size="32px" type="spinner" color="#fff" vertical
        >加载中...</van-loading
      >
    </div>
    <div class="registor-page">
      <div class="login-box">
        <div class="box-title">
          <div>注册</div>
          <div style="font-size: 16px">REGISTER</div>
        </div>
        <div>
          <div class="input-box">
            <input
              class="input"
              type="text"
              v-model="form.loginName"
              placeholder="手机号"
            />
          </div>
          <div class="input-box">
            <input
              class="input"
              type="text"
              v-model="form.smsCheckCode"
              placeholder="验证码"
              style="width: 120px"
            />
            <div
              class="verify-code-btn"
              @click="getVerify"
              :class="canGetVerify ? 'enable' : ''"
            >
              {{ verifyText }}
            </div>
          </div>
          <div class="input-box">
            <input
              class="input"
              :type="pwdView ? 'text' : 'password'"
              v-model="form.loginPwd"
              placeholder="请输入密码"
            />
            <img
              @click="pwdView = !pwdView"
              v-if="!pwdView"
              src="../assets/eye.png"
              class="eye-icon"
            />
            <img
              @click="pwdView = !pwdView"
              v-else
              src="../assets/eye-close.png"
              class="eye-icon"
            />
          </div>
          <div class="input-box">
            <input
              class="input"
              :type="repwdView ? 'text' : 'password'"
              v-model="form.reLoginPwd"
              placeholder="再次输入密码"
            />
            <img
              @click="repwdView = !repwdView"
              v-if="!repwdView"
              src="../assets/eye.png"
              class="eye-icon"
            />
            <img
              @click="repwdView = !repwdView"
              v-else
              src="../assets/eye-close.png"
              class="eye-icon"
            />
          </div>
          <div class="tips">
            注：密码为8-20位数字或字母，且至少包含一个数字一个字母
          </div>
<!--          <div class="input-box">-->
<!--            <input-->
<!--                class="input"-->
<!--                type="text"-->
<!--                v-model="form.lawUserPno"-->
<!--                placeholder="推荐人用户编号"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="input-box">-->
<!--            <input-->
<!--                class="input"-->
<!--                type="text"-->
<!--                v-model="form.userPno"-->
<!--                placeholder="推荐人用户编号"-->
<!--            />-->
<!--          </div>-->
          <van-checkbox
            v-model="checked"
            checked-color="#e83828"
            style="margin-top: 32px"
          >
            <div>
              我已阅读并同意<span
                style="color: #046df3"
                @click="$router.push('/userAgreement')"
                >《用户协议》</span
              >
            </div>
          </van-checkbox>
          <div class="btn" @click="register">注册</div>
        </div>
<!--        <div class="tool-box">-->
<!--          <div class="tool" @click="$router.push('/download')">-->
<!--            已有账号？去下载-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <van-overlay :show="show">
      <div class="wrapper">
        <div>
          <van-loading type="spinner" color="#fff" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { service } from "../utils/request";
import { Dialog } from "vant";
import { Overlay } from "vant";
export default {
  name: "App",
  components: {
    vanOverlay: Overlay,
  },
  data() {
    return {
      loading: false,
      phoneReg: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      pwdReg: /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{8,20}$/,
      form: {
        loginName: "",
      },
      verifyText: "获取验证码",
      canGetVerify: true,
      pwdView: false,
      repwdView: false,
      checked: false,
      show: false,
    };
  },
  mounted() {
    this.userPid = this.getQueryString("userPid") || "";
  },
  methods: {
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return decodeURIComponent(r[2]);
      return null;
    },

    async getVerify() {
      if (!this.form.loginName || !this.phoneReg.test(this.form.loginName))
        return alert("请输入手机号");
      if (!this.canGetVerify) return;

      this.loading = true;
      const res = await service.postForm("/app/register/check-login-name", {
        loginName: this.form.loginName,
      });
      if (res.data.code === "00000") {
        if (res.data.data) {
          const _res = await service.postForm("/app/register/send-sms-code", {
            loginName: this.form.loginName,
          });
          if (_res.data.code === "00000") {
            this.loading = false;
            this.canGetVerify = false;
            let interval = null;
            if (interval) return;
            let num = 59;
            this.verifyText = `重新获取（${num}s）`;
            interval = setInterval(() => {
              num--;
              this.verifyText = `重新获取（${num}s）`;
              if (num <= 0) {
                clearInterval(interval);
                this.canGetVerify = true;
                this.verifyText = "获取验证码";
                this.interval = null;
              }
            }, 1000);
          } else {
            this.loading = false;
            Dialog({ message: _res.data.msg || "网络错误，请重试" });
          }
        } else {
          this.loading = false;
          Dialog({ message: "该用户已注册" });
        }
      } else {
        this.loading = false;
        Dialog({ message: res.data.msg || "网络错误，请重试" });
      }
    },
    async register() {
      if (!this.checked) {
        return Dialog({ message: "请勾选用户协议" });
      }
      if (!this.form.loginName) {
        return Dialog({ message: "请输入手机号" });
      }
      if (!this.phoneReg.test(this.form.loginName)) {
        return Dialog({ message: "请输入正确的手机号" });
      }
      if (!this.form.smsCheckCode) {
        return Dialog({ message: "请输入验证码" });
      }
      if (!this.form.loginPwd) {
        return Dialog({ message: "请输入密码" });
      }
      if (!this.pwdReg.test(this.form.loginPwd)) {
        return Dialog({ message: "密码格式有误" });
      }
      if (!this.form.reLoginPwd) {
        return Dialog({ message: "请在此输入密码" });
      }
      if (this.form.loginPwd !== this.form.reLoginPwd) {
        return Dialog({ message: "两次密码输入不一致" });
      }
      this.show = true;
      let res = await service.post("/app/register", {
        ...this.form,
        userSource: 0,
        userPid: this.userPid,
      });
      this.show = false;
      if (res.data.code === "00000") {
        Dialog({ message: "注册成功！" });
        // Dialog.confirm({
        //   title: "注册成功",
        //   message: "赶快下载app登录吧～",
        //   confirmButtonText: "去下载",
        //   cancelButtonText: "稍后",
        // })
        //   .then(() => {
        //     this.$router.push("/download");
        //   })
        //   .catch(() => {
        //     // on cancel
        //   });
      } else {
        Dialog({ message: res.data.msg || "网络错误，请重试" });
      }
    },
  },
};
</script>

<style scoped>
.loading {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 40vw;
  height: 40vw;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html,
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.registor-page {
  background-image: url("../assets/top-back.png");
  background-position: center top;
  background-size: 100% auto;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-color: #f1f1f1;
  padding-top: 190px;
  box-sizing: border-box;
  padding-bottom: 16px;
}

.login-box {
  width: 90vw;
  background: #fff;
  margin: 0 5vw;
  border-radius: 8px;
  padding: 16px 16px 32px;
  box-sizing: border-box;
}

.box-title {
  font-size: 32px;
  text-align: center;
  margin-bottom: 16px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
.input-box {
  height: 44px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  border: 1px solid #999;
  margin-top: 16px;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
}

.input-box .input {
  display: block;
  flex: 1;
}

.tips {
  font-size: 14px;
  color: #666;
  margin-top: 12px;
  padding: 0 16px;
}

.verify-code-btn {
  color: #999;
  min-width: 6em;
  flex-shrink: 0;
  text-align: center;
}

.btn {
  margin-top: 24px;
  background: #e83828;
  color: #fff;
  text-align: center;
  line-height: 44px;
  border-radius: 22px;
}

.tool-box {
  margin-top: 12px;
  padding: 0 calc(5vw + 12px);
}

.tool {
  text-align: right;
  color: #e83828;
}

.enable {
  color: #e83828;
}

.eye-icon {
  width: 16px;
  height: 16px;
}

.input {
  border: none;
  outline: none;
}
.flex-a-c {
  display: flex;
  align-items: center;
  font-size: 14px;
}
</style>
